<template>
  <v-sheet
      :style="playerStyle"
      style="position:fixed; z-index: 999"
      dark :class="[{'fill-height' : !isCollapsed}]"

  >

    <div style="position: relative; height: 100%; width: 100%">
        <v-sheet
            style="position: absolute; right: 15px; top: 15px; z-index : 100; border-radius: 50%"
            color="#222222dd"
            @mouseenter="controlsHover = true"
            @mouseleave="controlsHover = false">
          <v-btn
              v-if="displayControls || isEmbed"
              :large="isEmbed"
              @click="$emit('input' , false)"
              icon>
            <v-icon :large="isEmbed">mdi-close</v-icon>
          </v-btn>
        </v-sheet>

      <div class="fill-height">
        <wsVideoViewer
            :key="trigger"
            :component="component"
            full-screen
            :is-collapsed="isCollapsed"
            @toggleCompact="isCollapsed=!isCollapsed"
            @controlsDisplay="displayControls = $event"
            :externalHover="controlsHover"
            @embed="isEmbed = $event"
        />
      </div>
    </div>

  </v-sheet>
</template>

<script>
export default {
  name: "wsVideoPlayer",
  props: {
    value : {
      type : Boolean,
      default  : false
    },
    name : {
      type : String
    },
    component : {
      type : Object,
      default() {return {} }
    }
  },
  data() {
    return {
      isCollapsed : false,
      trigger : 0,
      displayControls : true,
      controlsHover : false,
      isEmbed : false
    }
  },
  computed : {

    playerStyle() {
      if (!this.isCollapsed ) {
        return 'top: 0; bottom: 0; left: 0; right: 0;'
      }
      if ( this.SM ) {
        return 'height : 154px !important; width : 290px; bottom : 15px; right : 15px;'
      }
      return 'height : 154px !important; width : 275px; bottom : 25px; right : 25px;'

    }
  },
  watch : {
    component : {
      handler() {
        this.trigger++
      }
    }
  }
}
</script>

<style scoped>

</style>
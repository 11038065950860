<template>
  <ws-dialog
      v-model="display"
      :title="$t('Title')"
      @save="$emit('save')"
      blank
      no-padding
      width="528"
      no-navigation
      :background-color="wsLIGHTCARD"
      :portal="SM"
  >

    <v-sheet :color="wsATTENTION" dark class="d-flex align-center justify-space-between pa-4">
      <div class="d-flex align-center pr-2">
        <h4 style="color : #ffffff" class="shorten-text">{{ $t('UpdateDescription') }}</h4>
      </div>
      <v-btn @click="display = false;" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-sheet>

    <v-sheet :color="wsLIGHTCARD">
      <v-img
          v-if="entityData.img"
          :height="!SM ? '334' : '200'"
          :src="entityData.img || null"
          transition="xxx"
      />
      <v-sheet v-else class="d-flex justify-center"  :height="!SM ? '334' : '200'">
        <img
            :height="!SM ? '334' : '200'"
            width="auto"
            src="/library/img/support/updates.png"
        />
        <!--        -->
      </v-sheet>

      <v-sheet class="mx-auto pt-4 px-3" color="transparent" max-width="448" width="100%">

        <!-- Date, Reading time , socials -->
        <h5 class="wsDARKLIGHT font-weight-regular mb-4">
          <span v-if="entityData.name">{{ $t(`support.updates.types.${entityData.type}`) }} ·</span> {{ PARSE_DATE(entityData.date , false ,true).toLowerCase() }}
        </h5>
        <!-- Title -->
        <h3 style=" line-height: 1.2; "
            :style="`color : ${wsDARKER};`"
            class="mb-4">
          {{ entityData.name || $t(`support.updates.types.${entityData.type}`) }}
        </h3>

        <ws-text-viewer
            class="mt-5"
            :value="entityData.content"
            font-size="16"
            is-public
            video-height="248"
        />
      </v-sheet>
    </v-sheet>


    <!-- CONTENT-->

  </ws-dialog>
</template>

<script>
export default {
  name: "updateDescriptionDialog",
  props : {
    value : {
      type : Boolean
    },
  },
  data() {
    return {
      display : false
    }
  },
  watch : {
    display() {
      if ( this.value !== this.display ) {
        this.$emit('input' , this.display)
      }
    },
    value() {
      if ( this.value !== this.display ) {
        this.display = this.value
      }
    }
  },
  computed : {
    entityData() {
      return this.$store.state.events.updateDescription || {}
    }
  },
  methods : {
    closeDialog : true
  },
  mounted() {
    if ( this.value ) {
      this.display = this.value
    }
    this.$store.dispatch('events/DELETE_EVENTS','update_description')
  }
}
</script>

<style scoped>

</style>
<template>
  <v-sheet
      :class="[ { 'pa-3' : !SM } , { 'py-1' : SM } , { 'px-3' : SM } , {'player' : !SM} , { 'player_mobile' : SM }  ]"
      dark
      elevation="1"
      :width="!SM ? '300' : null"

  >
    <div v-if="!SM" class="d-flex justify-space-between mb-2">
      <h6 >
        {{ $store.state.audioPlayer.title }}
      </h6>
      <v-btn small @click="$store.state.audioPlayer.display = false" icon><v-icon small >mdi-close</v-icon></v-btn>
    </div>

    <wsAudio
        @close="$store.state.audioPlayer.display = false"
        :file="$store.state.audioPlayer.src"
        :color="wsACCENT"
        :compact="SM"
        flat
        :display-close="SM"
        auto-play


    />



  </v-sheet>
</template>

<script>
import wsAudio from "@/components/UI/audioPlayer/wsAudio";
export default {
  name: "wsAudioPlayer",
  components : {
    wsAudio
  },
  beforeDestroy() {
    this.$store.state.audioPlayer.title = ''
    this.$store.state.audioPlayer.src = ''
  }

}
</script>

<style scoped>
.player {
  position: fixed;
  z-index: 333;
  bottom: 15px;
  right: 15px;
}
.player_mobile {
  position: fixed;
  z-index: 333;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
<template>
     <v-sheet
         v-if="$store.state.dashboard.sideMenuMobile"
         :color="wsDASHBACKGROUND"
         class="fill-height"
         style="position:fixed; top:0;bottom: 0;left: 0;right: 0; z-index: 999"
     >



       <v-row class="no-gutters flex-wrap flex-column fill-height mb-4">

         <v-col cols="auto" class="shrink py-0" >
           <v-sheet :color="wsDARKER" dark  class="d-flex justify-space-between py-3 ">

             <div class="d-flex align-center">
               <v-avatar  style="border : 2px solid white"
                          size="56" class="mr-3 ml-4">
                 <v-img  v-if="$store.state.auth.userImg" :src="$store.state.auth.userImg">
                   <template slot="placeholder">
                     <v-img src="@/assets/placeholder/user_blank.jpg" />
                   </template>
                 </v-img>
                 <v-icon dark v-else size="32">mdi-account</v-icon>
               </v-avatar>
               <div>
                 <h5 >{{ $store.state.auth.userName }}</h5>
                 <h5 class="font-weight-medium" >{{ $store.state.auth.userEmail }}</h5>
                 <h5 class="font-weight-light">{{ $t('LinkKey') }}: <span class="font-weight-bold">{{ $store.state.auth.userLinkKey }}</span></h5>
               </div>
             </div>

             <v-btn icon @click="$store.state.dashboard.sideMenuMobile = false">
               <v-icon> mdi-close </v-icon>
             </v-btn>

           </v-sheet>
         </v-col>

         <v-col cols="auto" class="grow" style="height: 100px"  >
           <v-sheet light :color="wsDASHBACKGROUND" class="fill-height  overflow-y-auto ">
             <menuElementsBusiness :admin="admin" />
           </v-sheet>
         </v-col>


       </v-row>



     </v-sheet>
</template>

<script>
import {mapState} from 'vuex';

import menuElementsBusiness from "@/components/pages/businessDashboard/UI/sidebarBusiness/menuElementsBusiness";
export default {
  name: "sideBarBusiness",
  props : {
    admin : {
      type : Boolean,
      default : false
    }
  },
  components : {
    menuElementsBusiness
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Real-Time', icon: 'mdi-clock' },
      { text: 'Audience', icon: 'mdi-account' },
      { text: 'Conversions', icon: 'mdi-flag' },
    ],
  }),
  computed : {
    ... mapState('dashboard',['sideMenu']),

    displayCondition() {
      if ( this.displayShow(['sm'])) {
       return this.sideMenu
      }
      return true
    },
    
    menuStyleClass() {
      if ( this.sideMenu ) {
        if ( this.displayShow(['sm'])) {
          return 'menuActiveMobile'
        }
        return 'menuActive'
      }
      return ''
    }
  }


}
</script>

<style scoped>

.menu_header {
  height: 70px;
}

.menu {
  position:fixed;
  width:60px;
  height:100%;
  background-color: white;
  color: #2c3e50;
  float:left;
  transition: all 0.3s ease-out;
  overflow-y: auto;
}
.menuActiveMobile {
  position: fixed;
  display: block;
  z-index: 999999;
  width: 100%;
  transition: all 0.3s ease-out;
}
.menuActive {
  width:250px !important;
  transition: all 0.3s ease-out;
}
.menuActive li {
  padding-left: 35px !important;
}

.menu ul {
  padding:0;
}
.menu li {
  list-style: none;
  margin: 0px;
  padding:5px;
  padding-left:17px;
  transition: all 0.3s ease-out;
}
.menu li a {
  color: #2c3e50;
  text-decoration: none;
}
.menu a:hover {
  transition: all 0.3s ease-out;
  color: antiquewhite;
}
.menu li:hover {
  background-color: #ffffff33;
}
</style>
<template>
  <ws-dialog
      v-if="$store.state.courses.displayCourseCompletionDialog"
      v-model="$store.state.courses.displayCourseCompletionDialog"
      :title="$t('CourseFinished')"
      persistent
      width="700"
      :save-text="!isCompleted ? $t('ReceiveCertificate') : $t('GotoCertificates')"
      :cancel-text="!isCompleted ? $t('Later') : $t('Close')"
      @save="handleSaveButton"
  >

    <v-sheet :min-height="!isCompleted ? '320' : null">

      <template v-if="!isCompleted">
        <h4> <span>{{ $t('Course_')}}</span>: {{ course.name }}</h4>
        <h5 class="mb-5 mt-3" >
          {{ accordionItems.length > 1 ?   $t('certificates.student.issue.langs') : $t('certificates.student.issue.text') }}
        </h5>
        <ws-accordion
            v-if="accordionItems.length > 0"
            :items="accordionItems"
        >

          <template #header="{item,expand}">
            <ws-check-box
                v-model="entityData.langs[item.value]"
                @input="expand(item)"
                @prevent="notify($t('certificates.student.issue.no_lang_warning'), 'warning')"
                :prevent-select="preventSelectCondition(item)"
                :small="false"
                class="ml-3"
            />
            <h4>{{ item.name }}</h4>
          </template>

          <template #item="{item}">
            <div class="pa-5">
              <v-row>
                <v-col cols="12" md="6" >
                  <ws-text-field
                      v-model="entityData.firstnames[item.value]"
                      :label="$t('Firstname')"
                      :placeholder="$t('EnterFirstname')"
                  />
                </v-col>
                <v-col cols="12" md="6" >
                  <ws-text-field
                      v-model="entityData.lastnames[item.value]"
                      :label="$t('Lastname')"
                      :placeholder="$t('EnterLastname')"
                  />
                </v-col>
              </v-row>
              <h5 class="mt-3" :style="`color : ${wsDARKLIGHT}`">{{  $t('certificates.student.issue.name_description_text')  }}: {{ LANGUAGE_TEXT(item.value) }}</h5>
            </div>

          </template>

        </ws-accordion>
      </template>
      <template v-else>
        <h4 class="text-center my-9"> {{ accordionItems.length > 1 ?  $t('certificates.student.issue.completed_langs') : $t('certificates.student.issue.completed') }}</h4>
      </template>
    </v-sheet>



  </ws-dialog>
</template>

<script>
import {mapActions} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "courseCompletionDialog",
  mixins : [languagesList],
  data() {
    return {
      accordionItems : [],
      course : {},
      entityData : {
        firstnames : {},
        lastnames : {},
        langs : {}
      },
      certificates : {},
      isCompleted : false,
      certificateRecord : {},
    }
  },
  computed : {
    selectedLangsCount() {
      let selectedLangsCount = 0
      if ( !this.entityData.langs ) {
        return 0
      }
      if ( Object.keys(this.entityData.langs).length === 0 ) {
        return Object.keys(this.entityData.langs)
      }

      Object.keys(this.entityData.langs).forEach(lang => {
        if ( this.entityData.langs[lang]) {
          selectedLangsCount++
        }
      })
      return selectedLangsCount
    }
  },
  methods : {
    ...mapActions('certificates' , [
        'GET_CERTIFICATE_ISSUE_DATA',
        'ISSUE_STUDENT_CERTIFICATE'
    ]),
    preventSelectCondition(item) {
      if ( this.selectedLangsCount === 1 ) {
        if (this.entityData.langs[item.value]) {
          return true
        }
      }
      return false
    },
    handleSaveButton() {
      !this.isCompleted ? this.issueCertificate() : this.gotoCertificates()
    },
    gotoCertificates() {
      this.$router.push(this.businessDashLink('certificates?id=' + this.certificateRecord.uuid))
      this.$store.state.courses.displayCourseCompletionDialog = false
    },

    async issueCertificate() {

      let validation = true;
      Object.keys(this.entityData.langs).forEach(lang => {
        if ( this.entityData.langs[lang] ) {
          if ( !this.entityData.firstnames[lang]) {
            this.notify(this.$t('EnterFirstname') , 'warning')
            validation = false
          }
          if ( !this.entityData.lastnames[lang]) {
            this.notify(this.$t('EnterLastname') , 'warning')
            validation = false
          }
        }
      })

      if ( !validation ) {
        return
      }


      this.entityData.course_id = this.course.uuid
      let result = await this.ISSUE_STUDENT_CERTIFICATE(this.entityData)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'warning')
      }
      this.certificateRecord = result
      this.isCompleted = true
    },
    async initPage() {
      let result = await this.GET_CERTIFICATE_ISSUE_DATA(this.$store.state.courses.courseCompletionId)
      if ( !result ) {
        return
      }
      this.course = result.course
      this.certificates = result.certificates

      Object.keys(this.certificates ).forEach(lang => {
        this.accordionItems.push({
          name : this.LANGUAGE_TEXT(lang),
          value : lang ,
          hideExpandArrows : true,
          expanded  : false,
        })
        this.entityData[lang] = false
      })
      if ( this.certificates[this.LANG]) {
        this.entityData.langs[this.LANG] = true
        this.entityData.firstnames[this.LANG] = this.$store.state.auth.user.firstname
        this.entityData.lastnames[this.LANG]  = this.$store.state.auth.user.lastname
        this.expandSelectedLanguage(this.LANG)
      } else if (this.certificates[this.course.default_lang]) {
        this.entityData.langs[this.course.default_lang] = true
        this.entityData.firstnames[this.course.default_lang] = this.$store.state.auth.user.firstname
        this.entityData.lastnames[this.course.default_lang]  = this.$store.state.auth.user.lastname
        this.expandSelectedLanguage(this.course.default_lang)
      } else {
        this.entityData.langs[Object.keys(this.certificates)[0]] = true
        this.entityData.firstnames[Object.keys(this.certificates)[0]] = this.$store.state.auth.user.firstname
        this.entityData.lastnames[Object.keys(this.certificates)[0]]  = this.$store.state.auth.user.lastname
        this.expandSelectedLanguage(Object.keys(this.certificates)[0])
      }

      this.entityData = this.COPY(this.entityData)

    },
    expandSelectedLanguage(lang , value = true) {
      let index = this.accordionItems.findIndex(el => el.value === lang)
      if ( index === 1 ) {
        return
      }
      this.accordionItems[index].expanded = value
     }
  },
  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.courses.displayCourseCompletionDialog = false
    this.$store.state.courses.courseCompletionId = null
  }
}
</script>

<style scoped>

</style>
<template>
  <v-fade-transition>
    <v-sheet
        :color="wsACCENT + 'aa'"
        style="
        position: fixed;
        z-index: 999999998;
        left: 0; right: 0; top:0; bottom:0;"
    >
      <v-sheet :color="wsLIGHTCARD"
               elevation="3"
               class=" wsRoundedHalf "
               style="position: fixed;
             z-index: 999999999"
               :style="!SM ? 'left: 10%; right: 10%; top:10%; bottom:10%;' : 'left: 0; right: 0; top:0; bottom:0;'"
      >
        <div class="d-flex justify-end align-center pa-3">
          <v-btn @click="$store.state.MAINTENANCE_ON = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </div>

        <div style="width: 100%" class="d-flex align-center justify-center fill-height">
          <div class="mt-n5">
            <div class="d-flex justify-center">
              <v-icon class="mx-auto" size="72" :color="wsATTENTION">mdi-wrench-clock</v-icon>
            </div>

            <h1 v-if="!SM" class="text-center mt-5">{{ $t('MaintenanceModeOn') }}</h1>
            <h3 v-if="SM" class="text-center mt-5">{{ $t('MaintenanceModeOn') }}</h3>
            <h3 v-if="!SM" class="text-center" style="max-width: 700px">{{ $t('MaintenanceModeDescription') }}</h3>
            <h5 v-else class="text-center">{{ $t('MaintenanceModeDescription') }}</h5>
            <div class="d-flex justify-center  mt-5">
              <v-btn @click="reloadPage" class="noCaps" elevation="0" large dark  :color="wsATTENTION">
                <v-icon>mdi-refresh</v-icon>
                {{  $t('RefreshPage')  }}
              </v-btn>
            </div>

          </div>

        </div>


      </v-sheet>
    </v-sheet>

  </v-fade-transition>

</template>

<script>
export default {
  name: "maintenanceModeWindow",
  methods : {
    reloadPage() {
      location.reload()
    }
  }
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./planAndPaymentsActiveOrders.vue?vue&type=template&id=5ff1326d&scoped=true&"
import script from "./planAndPaymentsActiveOrders.vue?vue&type=script&lang=js&"
export * from "./planAndPaymentsActiveOrders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ff1326d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VChip,VSheet})

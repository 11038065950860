<template>
  <div>

    <v-sheet
        v-if="!onlyWarning || (onlyWarning && displayExpired)"
        class="justify-space-between py-4"
        :color="color"
        :dark="displayExpired || (displayEndSoon && !displayTrial)"
        :class="[{'d-flex' : !SM} , {'px-10' : !SM} , {'px-4' : SM} , {'wsRoundedHalf' : onlyWarning}]"

    >
      <div class="d-flex">
        <v-icon v-if="!SM" class="mr-3" :color="displayExpired || (displayEndSoon && !displayTrial) ? '#ffffff' : wsATTENTION">
          {{ !displayExpired ? 'mdi-star' : 'mdi-alert' }}
        </v-icon>
        <div>
          <h4 :style="`color : ${displayExpired || (displayEndSoon && !displayTrial) ? '#ffffff' : wsDARKER}`">
            {{  title  }}
          </h4>
          <h5 :style="`color : ${displayExpired || (displayEndSoon && !displayTrial) ? '#ffffff' : wsDARKER}`" :class="[{'mt-2' : SM}]" class="font-weight-regular">
            {{ description }}
          </h5>
        </div>
      </div>

      <ws-button
          v-if="!onlyWarning"
          :to="businessDashLink('plans_payments')"
          :label="!$store.state.business.selectedBusiness.has_one_payment ? 'PaymentPlans' : 'OpenPayments'"
          :left-icon="displayTrial ? 'mdi-star' : 'mdi-credit-card'"
          :block="SM"
          :outlined="displayExpired || (displayEndSoon && !displayTrial)"
          :color="displayExpired || (displayEndSoon && !displayTrial) ? '#ffffff' : null"
          :class="[{'mt-3' : SM}]"
      />

    </v-sheet>

  </div>

</template>

<script>
export default {
  name: "paymentPlanNotification",
  props: {
    onlyWarning : {
      type : Boolean,
      default: false
    }
  },
  computed : {
    color() {
      if (this.displayEndSoon && !this.displayTrial) {
        return this.wsATTENTION
      }

      if (this.displayExpired) {
        return this.wsWARNING
      }

      return this.wsLIGHTCARD
    },
    title() {
      const daysLeft = ` (${this.$tc('leftDays', this.daysLeft)})`

      let title = `${this.$t('PaymentPlan')}: ${this.$store.state.business.selectedBusiness.business_plan_name}`

      if (this.displayTrial) {
        title =  this.$t('start.preheader.title') + daysLeft
        if ( this.daysLeft < 7) {
          title =  this.$t('TrialEndSoon') + daysLeft
        }
      }
      else if (this.displayEndSoon) {
        title =  this.$t('PaymentPlanEndSoon') + daysLeft
      } else if (this.displayExpired) {
        title = this.$t('PaymentPlanEnded')
      }

      return title
    },
    description() {
      let description = `${this.$t('SubscriptionActiveDescription')}` + ' ' + this.activeTillDate

      if ( this.displayTrial) {
        description = this.$t('start.preheader.text')
      }
      else if (this.displayEndSoon) {
        description = this.$t('PaymentPlanEndSoonDescription') + ' ' + this.deactivationDate
      }
      else if (this.displayExpired) {
        description = this.$t('PaymentPlanEndSoonDescription') + ' ' + this.deactivationDate
      }

      return description
    },
    displayTrial() {
      if (this.displayExpired) {
        return false
      }
      return !this.$store.state.business.selectedBusiness.has_one_payment
    },
    displayEndSoon() {
      if ( this.displayExpired) {
        return false
      }
      return this.daysLeft < 7
    },
    displayExpired() {
      return this.daysLeft < 1
    },

    display() {
      return this.$store.state.dashboard.displayPaymentWarning
    },
    activeTill() {
      if ( !this.$store.state.business.selectedBusiness.active_till  ) {
        return null
      }
      let date = new Date(this.$store.state.business.selectedBusiness.active_till * 1000 )
      date.setHours(date.getHours() + 5 )
      return date
    },
    daysLeft() {
      if ( !this.activeTill ) {
        return 7
      }
      let date = new Date();
      let difference = this.activeTill.getTime() - date.getTime()
      return  (difference / (1000 * 3600 * 24) ).toFixed()
    },
    activeTillDate() {
      if ( !this.activeTill ) {
        return null
      }
      const date = this.activeTill
      return date.getDate() + ' ' + this.$t("Month_" + (date.getMonth()+1) ) + " " + date.getFullYear()
    },
    deactivationDate() {
      if ( !this.activeTill ) {
        return null
      }
      let date = new Date (this.activeTill.getTime() )
      date.setDate(date.getDate() + 5)
      return date.getDate() + ' ' + this.$t("Month_" + (date.getMonth()+1) ) + " " + date.getFullYear()
    },
    deletionData() {
      if ( !this.activeTill ) {
        return null
      }
      let date = new Date (this.activeTill.getTime() )
      date.setDate(date.getDate() + 60)
      return date.getDate() + ' ' + this.$t("Month_" + (date.getMonth()+1) ) + " " + date.getFullYear()
    }
  },
  watch : {
    daysLeft() {
      this.initPage()
    }
  },
  methods : {
    close() {
      this.$store.state.dashboard.displayPaymentWarning = false
    },
    initPage() {
      setTimeout(() => {
        if ( this.daysLeft < 5 ) {
          this.$store.state.dashboard.displayPaymentWarning = true
        }
      },2000)
    }
  },
  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.dashboard.displayPaymentWarning = false
  }

}
</script>

<style scoped>

</style>
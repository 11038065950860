<template>
  <v-sheet  :color="wsLIGHTCARD" class="fill-height overflow-y-auto"
            :class="[{'pt-12' : !SM}]"
  >

    <v-sheet max-width="800" class="mx-auto  wsRoundedHalf"
             :color="$store.state.business.selectedBusiness.alias && $store.state.dashboard.showBusinessToOwner || $store.state.dashboard.showBusinessToManager ? wsWHITE : 'transparent'">
      <div v-if="$store.state.business.selectedBusiness.alias && $store.state.dashboard.showBusinessToOwner || $store.state.dashboard.showBusinessToManager">
        <v-sheet :color="wsWARNING" :class="[{wsRoundedHalfTop: !SM}]">
          <payment-plan-notification   onlyWarning />
        </v-sheet>

        <ws-navigation
            :items="[{text : $t('Overview') , value : 'overview'} , {text : $t('Orders') , value : 'orders'}]"
            no-number
            padding="2px"
        >
          <template #item.overview>
            <plan-and-payments-overview
                class="py-0"
                :class="[{'pa-6' : !SM}]"
            />
          </template>
          <template #item.orders>
            <plan-and-payments-active-orders
                class="py-0"
                :class="[{'pa-6' : !SM}]"
            />
          </template>


        </ws-navigation>

        <support-button/>
      </div>

      <div v-else class="pa-8">

        <v-img src="@/assets/img/art/payment_expired.png" max-width="300" contain class="mx-auto"></v-img>
        <h1 class="text-center wsDARKER">{{ $store.state.business.selectedBusiness.name }}</h1>
        <h2 class="wsACCENT font-weight-regular text-center mx-auto mt-6" style="max-width: 600px" >{{ $t('SchoolDisabledPaymentExpired') }} <a href="https://westudy.ua" target="_blank" class="wsATTENTION linkHover noUnderline">WeStudy</a></h2>
        <h4 class="wsACCENT font-weight-regular text-center mt-6">{{ $t('ContactSchoolOwner') }}</h4>

      </div>



    </v-sheet>

  </v-sheet>

</template>

<script>
import paymentPlanNotification from "@/components/pages/businessDashboard/UI/paymentPlanNotification";
import planAndPaymentsOverview from "@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsOverview.vue";
import planAndPaymentsActiveOrders from "@/components/pages/businessDashboard/businessPlansAndPayments/planAndPaymentsActiveOrders.vue";


export default {
  name: "disabledPaymentBusinessPage",
  components : {
    planAndPaymentsOverview,
    paymentPlanNotification,
    planAndPaymentsActiveOrders,
    supportButton: () => import('@/modules/tech_support/components/SupportButton.vue')
  }
}
</script>

<style scoped>

</style>
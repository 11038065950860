export default [
    {
        owner : true,

        text: 'NewsletterNew',
        value: 'fine_mailer',
        icon: 'mdi-email',
        module : 'finemailernew',
        permission:'MODULE_FINEMAILERNEW',
        children : [
            {
                text: 'mailer.single.title',
                value: 'fine_mailer/letters',
                icon: 'mdi-robot' ,
                path: 'fine_mailer/letters',
                module : 'finemailernew',
            },
            // {
            //     text: 'mailer.automations.title',
            //     value: 'fine_mailer/campaigns',
            //     icon: 'mdi-robot' ,
            //     path: 'fine_mailer/campaigns',
            //     module : 'finemailernew',
            // },
            {
                text: 'Contacts',
                value: 'fine_mailer/contacts',
                icon: 'mdi-robot' ,
                path: 'fine_mailer/contacts',
                module : 'finemailernew',
            },
            {
                text: 'Templates',
                value: 'fine_mailer/templates',
                path: 'fine_mailer/templates',
                module : 'finemailernew',
            },
            // {
            //     text: 'Settings',
            //     value: 'fine_mailer/settings',
            //     path: 'fine_mailer/settings',
            //     module : 'finemailernew',
            // },


        ]
    },

]